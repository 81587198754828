import { FunctionComponent, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment/moment'

interface Props {
  title: string;
  text: string;
  onNo: () => void;
  onYes: (scrapeDate: string) => void;
}

export const ScrapeToolingDialog: FunctionComponent<Props> = (props) => {

  const [scrapeDate, setScrapeDate] = useState<string | undefined>(moment().toISOString())

  const changeScrappingDate = (date: Moment | null) => {
    if (date?.toISOString() !== null) {
      setScrapeDate(date?.toISOString())
    } else {
      setScrapeDate(moment().toISOString())
    }
  }

  return (
    <Dialog open={true} onClose={props.onNo} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <DialogContentText>{props.text}</DialogContentText>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Scrapped date"
              format="DD.MM.YYYY"
              value={moment(scrapeDate) ?? null}
              slotProps={{ textField: { fullWidth: true } }}
              onChange={changeScrappingDate}
            />
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onNo}>NO</Button>
        <Button
          onClick={() => props.onYes(scrapeDate ?? '')}
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  )
}
