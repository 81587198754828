import { REDIRECT_ADDRESS, AZURE_API, CLIENT_ID, TENANT_ID } from '../api/api'

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID ?? '',
    authority: `https://login.microsoftonline.com/${TENANT_ID ?? ''}`,
    redirectUri: REDIRECT_ADDRESS ?? '',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read', 'User.ReadBasic.All'],
}

export const toolingApiRequest = {
  scopes: [AZURE_API ?? ''],
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphUsersEndpoint: 'https://graph.microsoft.com/v1.0/users',
}
