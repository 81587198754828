import { FunctionComponent, useEffect, useState } from 'react'
import {
  Box, Container, Grid, Tab,
} from '@mui/material'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../../store/hooks'
import {
  selectToolingView, showActionError, hideActionError,
  load, ToolingViewState, updateAccounting,
} from '../../../store/tooling/toolingViewSlice'
import toolingApi from '../../../api/ToolingApi'
import SummaryCard from './SummaryCard'
import ErrorBox from '../../../components/ErrorBox'
import ErrorDialog from '../../../components/ErrorDialog'
import { checkPermission } from '../../../store/user/userSlice'
import {
  Permissions_Tooling_Delete, Permissions_Tooling_DraftDelete, Permissions_Tooling_Scrape,
} from '../../../api/types/permissions'
import ResponsibleCard from './components/ResponsibleCard'
import { AccountingCard } from './components/AccountingCard'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import TechnicalInformationCard from './components/TechnicalInformationCard'
import OperationTab from './tabs/operation/OperationTab'
import OrdersTab from './tabs/orders/OrdersTab'
import TabLabel from './components/TabLabel'
import FilesTab from './tabs/files/FilesTab'
import HistoryTab from './tabs/history/HistoryTab'
import DeleteToolingDialog from './components/DeleteToolingDialog'
import { selectToolingRepairs } from '../../../store/tooling/repairs/repairsSlice'
import { selectToolingTransfers } from '../../../store/tooling/transfers/transfersSlice'
import { DRAFT_STATUS, SCRAPPED_STATUS } from '../../../store/types/statuses'
import { UnitCard } from './UnitCard'
import { SuppliersCard } from './components/SuppliersCard'
import { StatusCard } from './StatusCard'
import { ToolingPageToolbar } from '../components/ToolingPageToolbar'
import { ScrapeToolingDialog } from './components/ScrapeToolingDialog'
import { saveStatus } from '../../../store/tooling/summarySlice'
import { ToolingStatuses } from '../../../api/types/StatusDto'
import { selectToolingEditAccounting, save } from '../../../store/tooling/accountingSlice'
import { ToolingAccountingDto } from '../../../api/types/ToolingDto'

const sum = (val1: number, val2: number): number => val1 + val2

const ToolingViewPage: FunctionComponent = () => {
  const { toolingId } = useParams()
  const toolingIdParsed = Number.parseInt(toolingId ?? '0')

  const location = useLocation()
  const hash = location.hash

  const mainTab = '#main'
  const ordersTab = '#orders'
  const operationsTab = '#operations'
  const filesTab = '#files'
  const historyTab = '#history'

  const [confirmDeleteTooling, setConfirmDeleteTooling] = useState(false)
  const [confirmScrapeTooling, setConfirmScrapeTooling] = useState(false)

  const [tabIndex, setTabIndex] = useState(hash || mainTab)

  const toolingViewState: ToolingViewState = useAppSelector(selectToolingView)
  const repairsState = useAppSelector(selectToolingRepairs)
  const transfersState = useAppSelector(selectToolingTransfers)
  const accountingState = useAppSelector(selectToolingEditAccounting)
  const dispatch = useAppDispatch()

  const isLoading = toolingViewState.status === 'loading'

  useEffect(() => {
    dispatch(load(toolingIdParsed))
  }, [dispatch, toolingIdParsed])

  useEffect(() => {
    setTabIndex(hash || mainTab)
  }, [hash])

  const navigate = useNavigate()

  const haveDeleteRight = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Delete))
  const haveDraftDeleteRight = useAppSelector((root) => checkPermission(root, Permissions_Tooling_DraftDelete))
  const haveScrapeRight = useAppSelector((root) => checkPermission(root, Permissions_Tooling_Scrape))

  const calcDeletePermissions = () => {
    const isDraftStatus = toolingViewState.tooling?.summary?.status?.name === DRAFT_STATUS
    const isScrappedStatus = toolingViewState.tooling?.summary?.status?.name === SCRAPPED_STATUS

    if (isScrappedStatus) {
      return (): boolean => false
    }

    const canUser = haveDraftDeleteRight && isDraftStatus
    return (): boolean => {
      if (haveDeleteRight) {
        return true
      } else {
        return !!canUser
      }
    }
  }
  const calcCanScrape = () => {
    const isScrappedStatus = toolingViewState.tooling?.summary?.status?.name === SCRAPPED_STATUS

    if (isScrappedStatus) {
      return (): boolean => false
    }
    return (): boolean => haveScrapeRight
  }

  const canDelete = calcDeletePermissions()
  const canScrape = calcCanScrape()

  const handleDelete = () => {
    setConfirmDeleteTooling(true)
  }

  const handleScrape = () => {
    setConfirmScrapeTooling(true)
  }

  const handleChangeTab = (tab: string) => {
    setTabIndex(tab)
    navigate(tab)
  }

  if (toolingViewState.status === 'failed') {
    return <ErrorBox code="500" description="Error while loading data" sx={{ minHeight: '90vh' }}></ErrorBox>
  }

  return (
    <Box my={4}>
      <Container maxWidth="lg">
        <ToolingPageToolbar
          title={`Tooling ${toolingViewState.tooling?.summary?.toolingNumber}`}
          backPath={'/tooling'}
          isLoading={isLoading}
          hideDeleteButton={canDelete()}
          hideScrapeButton={canScrape()}
          onDeleteButtonAction={handleDelete}
          onScrapeButtonAction={handleScrape}
        />
        <TabContext value={tabIndex}>
          <TabList onChange={(_, newValue) => handleChangeTab(newValue)}>
            <Tab label="Main" value={mainTab}/>
            <Tab
              label={<TabLabel title="Orders" count={toolingViewState?.tooling?.ordersCount ?? 0}/>}
              value={ordersTab}
            />
            <Tab
              label={<TabLabel title="Operations"
                               count={sum(repairsState.repairs.length, transfersState.transfers.length)}/>}
              value={operationsTab}
            />
            <Tab
              label={<TabLabel title="Files" count={toolingViewState?.tooling?.filesCount ?? 0}/>}
              value={filesTab}
            />
            <Tab label="History" value={historyTab}/>
          </TabList>

          <TabPanel value={mainTab} sx={{ padding: '0px' }}>
            <Box mt={2}>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12} md={9}>
                  <SummaryCard toolingId={toolingIdParsed}/>
                  <UnitCard toolingId={toolingIdParsed}/>
                  <SuppliersCard toolingId={toolingIdParsed}/>
                  <AccountingCard toolingId={toolingIdParsed}/>
                  <TechnicalInformationCard toolingId={toolingIdParsed}/>
                </Grid>
                <Grid item container xs={12} md={3} direction="column" spacing={5}>
                  <Grid item>
                    <ResponsibleCard toolingId={toolingIdParsed}/>
                  </Grid>
                  <Grid item>
                    <StatusCard/>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={ordersTab} sx={{ padding: '0px' }}>
            <OrdersTab toolingId={toolingIdParsed}/>
          </TabPanel>
          <TabPanel value={operationsTab} sx={{ padding: '0px' }}>
            <OperationTab toolingId={toolingIdParsed}/>
          </TabPanel>
          <TabPanel value={filesTab} sx={{ padding: '0px' }}>
            <FilesTab toolingId={toolingIdParsed}/>
          </TabPanel>
          <TabPanel value={historyTab} sx={{ padding: '0px' }}>
            <HistoryTab toolingId={toolingIdParsed}/>
          </TabPanel>
        </TabContext>

        {confirmScrapeTooling && (
          <ScrapeToolingDialog
            title="Scrape tooling"
            text={`Are you sure you want to scrape tooling ${toolingViewState.tooling?.summary?.toolingNumber}?`}
            onNo={() => setConfirmScrapeTooling(false)}
            onYes={async (scrapeDate) => {
              setConfirmScrapeTooling(false)

              const dto = {
                accountingNumber: accountingState.accountingNumber,
                accountingDate: accountingState.accountingDate,
                scrappingDate: scrapeDate,
                accountingDepreciationAsFixedAsset: accountingState.accountingDepreciationAsFixedAsset,
              } as ToolingAccountingDto

              await dispatch(save({ toolingId: toolingIdParsed, accounting: dto }))
                .unwrap()
                .then(() => {
                  dispatch(updateAccounting(dto))
                })
                .catch(() => {
                })

              await dispatch(saveStatus({ toolingId: toolingIdParsed, status: { status: { statusId: ToolingStatuses.Scrapped, name: ToolingStatuses[ToolingStatuses.Scrapped] } } }))
                .unwrap()
                .then(() => {
                  dispatch(load(toolingIdParsed))
                })
                .catch((err) => dispatch(showActionError(err)))
            }}
          />
        )}

        {confirmDeleteTooling && (
          <DeleteToolingDialog
            title="Delete tooling"
            text={`Are you sure you want to delete tooling ${toolingViewState.tooling?.summary?.toolingNumber}?`}
            onNo={() => setConfirmDeleteTooling(false)}
            onYes={(comment: string) => {
              setConfirmDeleteTooling(false)
              toolingApi
                .delete(toolingIdParsed, comment)
                .then(() => navigate('/tooling'))
                .catch((err) => dispatch(showActionError(err)))
            }}
          />
        )}

        <ErrorDialog
          open={toolingViewState.actionError !== undefined}
          title={toolingViewState.actionError?.name || 'Error'}
          text={toolingViewState.actionError?.message ?? ''}
          onOk={() => dispatch(hideActionError())}
        />
      </Container>
    </Box>
  )
}

export default ToolingViewPage
